@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  font-family: 'Press Start 2P';
  background-color: #0a192f;
}

.disclaimer {
  font-size: 0.5rem;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.privacy {
  background: #0a192f;
}

.terms {
  background: #0a192f;
}
